import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FadeIn from "../components/FadeIn"
import Books from "../images/books.png"

const Particulieren = () => (
  <Layout>
    <SEO title="Particulieren" />
    <h1>Voor wie</h1>
    <p>
      Iedereen heeft wel eens te maken met een situatie waarin uw leefomgeving
      ‘uit balans’ is: veranderende omstandigheden, tijdgebrek, onverwachte
      gebeurtenissen. Soms zijn deze situaties eenvoudig op te lossen, maar het
      kan ook zijn dat u vastloopt en dat u er niet toe komt om orde op zaken te
      stellen. Een aantal voorbeelden:
    </p>

    <div>
      <FadeIn src={Books} alt="Balans" right />

      <ul>
        <li>Er is een psychische zorgvraag</li>
        <li> U heeft te maken met structurele chaos en drukte in uw hoofd</li>
        <li>
          U heeft te maken met langdurige ziekte, waardoor u uw leven drastisch
          moet aanpassen.
        </li>
        <li> Er is sprake van verzamelwoede (hoarding)</li>
        <li> U raakt steeds meer de grip kwijt in uw dagelijks leven</li>
        <li>
          Er zijn psychiatrische problemen, die een verstoring vormen in u
          dagelijks functioneren
        </li>
      </ul>

      <p>
        Herkent u zich in één van bovenstaande voorbeelden? Dan is dat het
        moment om Balans in hoofd en huis in te schakelen.
      </p>
    </div>

    <Link
      to="/werkwijze"
      className="btn btn-outline-danger mt-4 mr-3 mb-2"
      role="button"
    >
      Bekijk werkwijze
    </Link>
    <Link to="/contact" className="btn btn-danger mt-4 mb-2" role="button">
      Neem contact op
    </Link>
  </Layout>
)

export default Particulieren
